import React from "react"
import { Container, Row, Col } from "reactstrap"
import { useStaticQuery, graphql, useFragmentQuery } from "gatsby"
import PageHeader from "../components/pageHeader"

import "./contact.scss"

// import Logo from "../images/logo1.png"
import Logo from "../images/oliver_logo_normal4.png"


import Smartphone from "../assets/svg/smartphone.svg"
import Telephone from "../assets/svg/phone.svg"
import Mail from "../assets/svg/mail.svg"
import Pin from "../assets/svg/map-pin.svg"

import FacebookSVG from "../assets/svg/facebook.svg"
import TwitterSVG from "../assets/svg/twitter.svg"
import InstagramSVG from "../assets/svg/instagram.svg"
import LinkedinSVG from "../assets/svg/linkedin.svg"

const ImpressumPage = () => {
 

  return (
    <section id="contact">
      {/* <PageHeader title="Impressum" /> */}
      <Container>
        <main className="contact-page section-lg">
          <Row>
            <Col lg="8" className="contact-form border-right pl-5 pr-5">
              {/* <h1 className="titelio text-center font-weight-bold">
                Die Webseite von 
              </h1> */}
              <p className="contact-description font-size-100 text-center text-muted mb-4">
                Eine Webseite von Gerold Lotmar
                
              </p>
             
              <div className="justify-content-center align-items-center text-center">

                {/* <img
src={Logo}
alt="garten und Kunst Logo"
className="border-bottom text-center img-fluid"
/>                */}

              </div>
            </Col>
            <Col lg="4" className="contact-info pl-5 pr-5">
              <aside className="contact-info">
                <section className="get-social border-bottom mb-4 pb-4">
                  {/* <p className="text-uppercase font-weight-bold">Social Media</p> */}
                  <Row className="ml-0">
                    <Col>
                    <p>Inhalt & Bilder sind Eigentum von Gerold Lotmar</p></Col>
                  </Row>
                </section>
                <section className="telephone border-bottom mb-4 pb-4">
                  {/* <p className="text-uppercase font-weight-bold">
                    
                  </p> */}
                  {/* <Row className="ml-0">
                    <Telephone className="fill-svg fill-color" />{" "}
                    <a href="tel:0774137433">077 413 74 33</a>
                  </Row> */}
                </section>
                {/* <section className="mobilephone border-bottom mb-4 pb-4">
                  <p className="text-uppercase font-weight-bold">
                    Mobile Phone
                  </p>
                  <Row className="ml-0">
                    <Smartphone className="stroke-svg stroke-color" />{" "}
                    {contact.mobile}
                  </Row>
                </section> */}
                <section className="email border-bottom mb-4 pb-4">
                  <p className="text-uppercase font-weight-bold">Webseiten Entwicklung</p>
                  <Row className="ml-0">
                    <Mail className="stroke-svg stroke-color" />
                    <a href="https://block-lab.ch" target="_blank"> Block Lab GmbH</a>
                  </Row>
                </section>
                <section className="address mb-4 pb-4">
                  <p className="text-uppercase font-weight-bold">Addresse</p>
                  {/* <Row className="ml-0 flex-nowrap">
                    <Pin className="fill-svg fill-color" /> <p>{address}</p>
                  </Row> */}
                </section>
              </aside>
            </Col>
          </Row>
        </main>
      </Container>
      {/* <iframe
        src={map}
        width="100%"
        height="450"
        frameborder="0"
        allowfullscreen=""
        title={`${title} office map`}
      ></iframe> */}
    </section>
  )
}

export default ImpressumPage
